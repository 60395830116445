import { isEqual } from 'lodash';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useFetchRecommendedTerms } from '@custom-hooks/search/useFetchRecommendedTerms';
import { trackSearchedRecommendedTerms } from '@utilities/tracking';
import { trackSearchSuggestionClicked } from '@utilities/tracking/search/searchedProducts/trackSearchSuggestionClicked';

import { Spinner } from '../../../../../../components-design-system/spinner/spinner';
import { SearchBarInput } from './components/input/searchBarInput';
import { SearchBarNoResults } from './components/noResults/noResults';
import { SearchBarPrompts } from './components/prompts/searchBarPrompts';
import { SearchBarResults } from './components/results/searchBarResults';
import $mobileHeaderSearchBar from './searchBar.module.scss';
import { SearchPrompt } from './type';

export interface MobileHeaderSearchBarProps {
    isModalOpen: boolean;
    searchPrompts: SearchPrompt[];
    setIsModalOpen: (isOpen: boolean) => void;
}

export const MobileHeaderSearchBarWithPromptsHeight =
    $mobileHeaderSearchBar.containerHeight;

const MobileHeaderSearchBarExperiment = ({
    isModalOpen,
    searchPrompts,
    setIsModalOpen,
}: MobileHeaderSearchBarProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const [input, setInput] = useState('');
    const [query, setQuery] = useState<string>('');
    const [searchTerms, setSearchTerms] = useState<(string | null)[]>([]);
    const elementId = 'product-search-bar-mobile';

    const {
        error: fetchSearchTermsError,
        fetchRecommendedTerm,
        loading: isLoading,
        queryId,
        results,
    } = useFetchRecommendedTerms();
    const router = useRouter();

    const PRODUCT_SEARCH_DEBOUNCE_TIME = 1000;

    const randomizedSearchPrompts = useMemo(
        () => searchPrompts.sort(() => Math.random() - 0.5),
        [searchPrompts],
    );

    const queryChangeAsync = useCallback(
        async (queryStr: string) => {
            if (queryStr.length > 0) {
                const { data } = await fetchRecommendedTerm({
                    variables: { searchTerm: queryStr },
                });
                trackSearchedRecommendedTerms({
                    query: queryStr,
                    source: elementId,
                    returnedRecommendedTerms:
                        data?.searchTermSuggestions?.searchSuggestionList,
                });
            }
        },
        [fetchRecommendedTerm],
    );

    const onSearchInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const inputValue = event.target.value;
        if (!isModalOpen) setIsModalOpen(true);
        setSearchTerms([]);
        setInput(inputValue);
        setQuery(inputValue);
    };

    const handleFocus = () => setIsModalOpen(true);

    const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && input.length > 0) {
            router.push(`/search?query=${input}`);
            if (document.activeElement !== document.body) {
                (document.activeElement as HTMLInputElement).blur();
            }
            setIsModalOpen(false);
        }
    };
    const handleLeftIconClick = () => {
        if (isModalOpen) {
            setInput('');
            setQuery('');
            setIsModalOpen(false);
        }
    };
    const handleRightIconClick = () => {
        setInput('');
        setQuery('');
    };

    useEffect(() => {
        if (!isEqual(searchTerms, results)) {
            setSearchTerms(results);
        }
    }, [results, searchTerms]);

    useEffect(() => {
        const debouncedQueryChangeAsync = debounce(
            queryChangeAsync,
            PRODUCT_SEARCH_DEBOUNCE_TIME,
            {
                leading: true,
                trailing: false,
            },
        );
        debouncedQueryChangeAsync(query);
    }, [query, queryChangeAsync]);

    useEffect(() => {
        if (fetchSearchTermsError) {
            enqueueSnackbar(
                fetchSearchTermsError?.message ?? fetchSearchTermsError,
            );
        }
    }, [fetchSearchTermsError, enqueueSnackbar]);

    useEffect(() => {
        setInput(
            typeof router.query.query === 'string' ? router.query.query : '',
        );
        setQuery(
            typeof router.query.query === 'string' ? router.query.query : '',
        );
    }, [router.query]);

    const recommendedSearchTerms = query ? searchTerms : [];

    const doShowLoadingSpinner = query && isLoading;
    const doShowSearchBarPrompts = !query && !isLoading;
    const doShowSearchResults =
        !isLoading && query && recommendedSearchTerms.length > 0;
    const doShowNoResultsMessage =
        !isLoading && query && recommendedSearchTerms.length === 0;

    return (
        <div className={$mobileHeaderSearchBar.container}>
            <SearchBarInput
                elementId={elementId}
                handleFocus={handleFocus}
                handleKeyUp={handleKeyUp}
                handleLeftIconClick={handleLeftIconClick}
                handleRightIconClick={handleRightIconClick}
                input={input}
                isModalOpen={isModalOpen}
                onSearchInputChange={onSearchInputChange}
            />
            {isModalOpen && (
                <div className={$mobileHeaderSearchBar.dropdown}>
                    {doShowLoadingSpinner && (
                        <div className={$mobileHeaderSearchBar.statusContainer}>
                            <Spinner />
                        </div>
                    )}
                    {doShowSearchBarPrompts && (
                        <SearchBarPrompts
                            searchPrompts={randomizedSearchPrompts}
                            setIsModalOpen={setIsModalOpen}
                        />
                    )}
                    {doShowSearchResults && (
                        <SearchBarResults
                            elementId={elementId}
                            queryId={queryId}
                            results={recommendedSearchTerms}
                            setInput={setInput}
                            setIsModalOpen={setIsModalOpen}
                            trackSearchSuggestionClicked={
                                trackSearchSuggestionClicked
                            }
                        />
                    )}
                    {doShowNoResultsMessage && <SearchBarNoResults />}
                </div>
            )}
        </div>
    );
};

export { MobileHeaderSearchBarExperiment };
