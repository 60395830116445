import React from 'react';

import $spacing from '@styles/spacing.module.scss';

type Space =
    | '8'
    | '12'
    | '16'
    | '20'
    | '24'
    | '28'
    | '32'
    | '36'
    | '40'
    | '48'
    | '56'
    | '64'
    | '72'
    | '80'
    | '96'
    | '120';

const spacerMap: { [key in Space]: string } = {
    8: $spacing.spacer8,
    12: $spacing.spacer12,
    16: $spacing.spacer16,
    20: $spacing.spacer20,
    24: $spacing.spacer24,
    28: $spacing.spacer28,
    32: $spacing.spacer32,
    36: $spacing.spacer36,
    40: $spacing.spacer40,
    48: $spacing.spacer48,
    56: $spacing.spacer56,
    64: $spacing.spacer64,
    72: $spacing.spacer72,
    80: $spacing.spacer80,
    96: $spacing.spacer96,
    120: $spacing.spacer120,
};

interface SpacerProps {
    size: Space;
}

const Spacer: React.FC<SpacerProps> = ({ size }) => {
    return <div className={spacerMap[size]} />;
};

export { Spacer };
