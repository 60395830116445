import Link from 'next/link';

import { Icon } from '@components-design-system/icon';
import { Text } from '@components-design-system/text';
import { SearchPrompt } from '@scenes/Dashboard/components/mobileHeader/components/searchBarWithPrompts/type';
import { trackSearchPromptClicked } from '@utilities/tracking/dashboard/trackSearchPromptClicked';

import $searchBarPrompts from './searchBarPrompts.module.scss';

interface SearchBarPromptsProps {
    searchPrompts: SearchPrompt[];
    setIsModalOpen: (value: boolean) => void;
}

const SearchBarPrompts = ({
    searchPrompts,
    setIsModalOpen,
}: SearchBarPromptsProps) => {
    const handleClick = (label: string, url: string) => {
        setIsModalOpen(false);
        trackSearchPromptClicked({ label, url });
    };

    return (
        <div className={$searchBarPrompts.searchPromptsContainer}>
            {searchPrompts.map((searchPrompt) => {
                const { label, iconVariant, url } = searchPrompt;
                return (
                    <Link
                        href={url}
                        onClick={() => handleClick(label, url)}
                        className={$searchBarPrompts.searchPrompt}
                        key={label}
                    >
                        <Text textClassName="body2bold">{label}</Text>

                        <div className={$searchBarPrompts.searchPromptIcon}>
                            <Icon height={36} variant={iconVariant} />
                        </div>
                    </Link>
                );
            })}
        </div>
    );
};

export { SearchBarPrompts };
