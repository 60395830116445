import { useEffect, useState } from 'react';

import { Spacer } from '@components-design-system/layout/spacer/spacer';
import { Text } from '@components-design-system/text';

import $mobileHeaderSearchBar from '../../searchBar.module.scss';

const SearchBarNoResults = () => {
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true);
        }, 500);
    }, []);
    return (
        <div className={$mobileHeaderSearchBar.statusContainer}>
            <Spacer size="16" />
            <Text textClassName="body1medium">
                {isVisible ? 'No results found' : ''}
            </Text>
            <Spacer size="16" />
        </div>
    );
};

export { SearchBarNoResults };
