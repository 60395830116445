import { useRouter } from 'next/router';
import { useFeatureFlagPayload } from 'posthog-js/react';

import { useFeatureFlagVariantKey } from '@custom-hooks/useFeatureFlagVariantKey';
import { FLAGS } from '@utilities/constants/featureFlags';

const useIsDisplaySearchPromptsEnabled = () => {
    const { id, default: defaultValue } = FLAGS.ENABLE_SEARCH_PROMPTS;

    const { query } = useRouter();

    const queryOverride = query?.showSearchPrompts as string | undefined;

    const variant = useFeatureFlagVariantKey(id, defaultValue);

    const payload = useFeatureFlagPayload(id);

    let result = false;

    if (queryOverride === 'true') {
        result = true;
    } else if (queryOverride === 'false') {
        result = false;
    } else if (variant === 'test') {
        result = true;
    }

    return {
        isDisplaySearchPromptsEnabled: result && payload,
        searchPrompts: payload,
    };
};

export { useIsDisplaySearchPromptsEnabled };
